import React, { ReactElement, useState } from 'react'

import Button from 'ui/atoms/Button/Button'

import { useTranslation } from 'react-i18next'

import styles from './wrikeFormHandler.module.less'

type WrikeFormHandlerProps = {
  iFrameSource: string
  title: string
}

export default function WrikeFormHandler({ iFrameSource, title }: WrikeFormHandlerProps): ReactElement {
  const { t } = useTranslation()

  const [wrikeFormIsOpen, setWrikeFormState] = useState(false)

  return (
    <>
      {wrikeFormIsOpen && (
        <div className={styles.WrikeFormHandler_Form}>
          <div className={styles.WrikeFormHandler_Button__close}>
            <Button link onClick={() => setWrikeFormState(!wrikeFormIsOpen)}>
              {t(`Close`)}
            </Button>
          </div>
          <iframe width="100%" height="100%" src={iFrameSource} frameBorder="1" title={title} />
        </div>
      )}
      <Button link style={{color: 'white'}} marginRight icon={`fal fa-comments`} onClick={() => setWrikeFormState(!wrikeFormIsOpen)}>
        {title}
      </Button>
    </>
  )
}
