import { apiClient } from 'api/apiClient/apiClient'
import { useAuth } from 'ui/components/AuthContext/AuthContext'

import { useQuery } from 'react-query'

type LatestValue = {
  value: number
  time: string | null
  source: `meas_values` | `mean_values` | `setting`
  max_level: number
  min_level: number
  end_level: number | null
}

type UnitType =
  | `HWB`
  | `CHP`
  | `Boiler`
  | `Turbine`
  | `TurbineLinear`
  | `Heatpump`
  | `Heatexchanger`
  | `FGC`
  | `FGCWithBypass`
  | `FGCWithoutBypass`
  | `Storage`
  | `Consumer`
  | `Producer`

export type Unit = DisplayNameObject & {
  id: number
  name: string
  display_name: string
  node: number
  commodities: number[]
  props: number[]
  category: string
  stack_order: number
  color: string
  url: string
  unit_type: UnitType
  latest_storage_value: LatestValue | null
  visible_in_calendar: boolean
}

export const UNITS_QUERY_KEY = `units`
export function useUnits(
  { optModel, ...queryParams }:
    {
      optModel?: number
      unit_type?: UnitType
      enabled?: boolean
    } = {}
) {
  const { activeSystem } = useAuth()
  const optModelId = optModel ?? activeSystem?.primary_opt_model?.id
  const params = { belongs_to_opt_model: optModelId, ...queryParams }

  return useQuery<Unit[]>(
    [UNITS_QUERY_KEY, params],
    () => {
      if (queryParams?.enabled === false) {
        return []
      }

      return apiClient<Unit[]>(`units`, { params })
    },
    {
      enabled: !!optModelId,
    })
}

export function useUnitByName({ optModel, name, ...queryParams }: { optModel?: number; name?: string } = {}) {
  const { activeSystem } = useAuth()
  const optModelId = optModel ?? activeSystem?.primary_opt_model?.id

  const params = { belongs_to_opt_model: optModelId, name: name, ...queryParams }

  return useQuery([UNITS_QUERY_KEY, params], () => apiClient<Unit[]>(`units`, { params }), {
    enabled: !!optModelId,
  })
}