import React, { useState, useRef, ReactElement } from 'react'

import { useLogoutMutation } from 'api/auth/auth.api'
import { useUser } from 'api/users/users.api'
import { useAuth } from 'ui/components/AuthContext/AuthContext'

import { Button, Hidden, Popper, Grow, Paper, ClickAwayListener, MenuItem, MenuList, Icon, Grid } from '@mui/material'
import classNames from 'classnames'

import sigholmLogo from '/img/sigholm_logo.png'

import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'


import { getDisplayUserName, useHasGlobalPermission } from 'helpers/global.helper/global.helper'

import styles from './ProfileMenu.module.less'
import LanguagePicker from './components/LanguagePicker/LanguagePicker'
import ProfileMenuItem from './components/ProfileMenuItem/ProfileMenuItem'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Wire = ({ children, ...props }: any): ReactElement => children(props)

function ProfileMenu(): ReactElement {
  const { t } = useTranslation()
  const { activeSystem } = useAuth()
  const [open, setOpen] = useState(false)
  const anchorEl = useRef<null | HTMLButtonElement>(null)
  const { mutateAsync } = useLogoutMutation()
  const hasViewUserAdminModuleAccess = useHasGlobalPermission(`view_user_admin_module`)
  function handleToggle(): void {
    setOpen(!open)
  }
  const { data: user, isLoading } = useUser()
  const userName = isLoading ? `` : getDisplayUserName(user, true)
  function handleClose(event: MouseEvent | TouchEvent | React.MouseEvent<HTMLLIElement>): void {
    if (anchorEl.current && anchorEl.current.contains(event.target as Node)) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <Button
        color="inherit"
        variant="outlined"
        ref={anchorEl}
        aria-owns={open ? `simple-menu` : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={styles.ProfileMenu_ProfileButton}
        data-testid="profile-menu-button"
      >
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item>
            <Icon className={classNames(`fas fa-user-circle`, styles.ProfileMenu_LeftIcon)} />
          </Grid>
          <Grid item>
            <div className={styles.ProfileMenu_UserName}>{userName ? userName : ``}</div>
          </Grid>
          <Grid item>
            <Hidden smDown>
              <div className={styles.ProfileMenu_CustomerIcon}>
                {activeSystem && activeSystem?.organization_logo_url ? (
                  <img height="80%" src={activeSystem.organization_logo_url} alt="customer logo" />
                ) : null}
              </div>
            </Hidden>
          </Grid>
        </Grid>
      </Button>
      <Popper open={open} anchorEl={anchorEl.current} transition disablePortal>
        {({ TransitionProps, placement }): ReactElement => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === `bottom` ? `center top` : `center bottom`,
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <Wire>
                    {(props: object): ReactElement => (
                      <Hidden smUp>
                        <MenuItem 
                          {...props} 
                          onClick={handleClose} 
                          className={styles.ProfileMenu_CustomerIconMenu}
                        >
                          <img
                            height="20%"
                            width="20%"
                            src={
                              activeSystem && activeSystem?.organization_logo_url
                                ? activeSystem.organization_logo_url
                                : sigholmLogo
                            }
                            alt="customer logo"
                          />
                        </MenuItem>
                      </Hidden>
                    )}
                  </Wire>
                  <ProfileMenuItem icon="fal fa-user-cog">
                    <Link className={styles.ProfileMenu_UserProfile} to="/userprofile">
                      {t(`Account settings`)}
                    </Link>
                  </ProfileMenuItem>

                  <ProfileMenuItem icon="fal fa-globe-europe">
                    <LanguagePicker />
                  </ProfileMenuItem>

                  {hasViewUserAdminModuleAccess && (
                    <ProfileMenuItem icon="fal fa-user-cog">
                      <Link className={styles.ProfileMenu_UserProfile} to="/adminsettings">
                        {t(`Admin settings`)}
                      </Link>
                    </ProfileMenuItem>
                  )}

                  <ProfileMenuItem
                    icon="fal fa-sign-out"
                    onClick={(): void => {
                      mutateAsync()
                    }}
                  >
                    {t(`Logout`)}
                  </ProfileMenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default withRouter(ProfileMenu)
