import React, { ReactElement, PropsWithChildren } from 'react'

import globalState from 'store/global/global'

import { useSnapshot } from 'valtio'

import NotificationCenter from '../NotificationCenter/NotificationCenter'

import styles from './Navigation.module.less'
import Sidebar from './components/Sidebar/Sidebar'
import Topbar from './components/Topbar/Topbar'
import { RoutesArray } from './types'

export type NavigationProps = PropsWithChildren<{
  routes: RoutesArray
}>

export default function Navigation({ routes, children }: PropsWithChildren<NavigationProps>): ReactElement {
  const globalSnap = useSnapshot(globalState)
  return (
    <>
      <Topbar
        drawerOpen={globalSnap.navigationIsOpen}
        setDrawerOpen={(navigationIsOpen) => {
          globalState.navigationIsOpen = navigationIsOpen
        }}
        notificationCenterOpen={globalSnap.notificationCenterIsOpen}
        setNotificationCenterOpen={(notificationCenterOpen) => {
          globalState.notificationCenterIsOpen = notificationCenterOpen
        }}
      />
      <Sidebar
        drawerOpen={globalSnap.navigationIsOpen}
        routes={routes}
        setDrawerOpen={(navigationIsOpen) => {
          globalState.navigationIsOpen = navigationIsOpen
        }}
      />
      <main className={styles.content}>
        <div className={styles.toolbar} />
        {children}
      </main>
      {globalSnap.notificationCenterIsOpen && <NotificationCenter />}
    </>
  )
}
