import React, { createContext, PropsWithChildren, ReactElement, useContext, useState } from 'react'

import { postStat } from 'api/stats/stats.api'

export enum TooltipViewMode {
  smallTooltips = 0,
  tableTooltips = 1,
  hideTooltips = 2,
}

export type LegendMode = `dropdown` | `button`

type ChartSettingsContextType = {
  legendMode: LegendMode
  setLegendMode: (mode: LegendMode) => void

  legendVisible: boolean
  toggleLegendVisible: () => void

  tooltipViewMode: TooltipViewMode
  setTooltipViewMode: (mode: TooltipViewMode) => void
}

const ChartSettingsContext = createContext<ChartSettingsContextType | undefined>(undefined)

export function ChartSettingsProvider({ children }: PropsWithChildren<unknown>): ReactElement {
  const [legendMode, setLegendMode] = useState<LegendMode>(`button`)
  const [legendVisible, setLegendVisible] = useState(true)
  const [tooltipViewMode, setTooltipViewMode] = useState<TooltipViewMode>(TooltipViewMode.tableTooltips)

  return (
    <ChartSettingsContext.Provider
      value={{
        legendMode,
        setLegendMode: (mode) => {
          postStat(`chart`, `set-legend-mode-${mode}`)
          return setLegendMode(mode)
        },
        legendVisible,
        toggleLegendVisible: () => {
          setLegendVisible(!legendVisible)
          postStat(`chart`, !legendVisible ? `show-legends` : `hide-legends`)
        },
        tooltipViewMode,
        setTooltipViewMode: (mode) => {
          postStat(`chart`, `set-tooltip-mode-${mode}`)
          return setTooltipViewMode(mode)
        },
      }}
    >
      {children}
    </ChartSettingsContext.Provider>
  )
}

export function useChartSettings(): ChartSettingsContextType {
  const context = useContext(ChartSettingsContext)
  if (context === undefined) {
    throw new Error(`useChartSettings must be used within ChartSettingsProvider`)
  }
  return context
}
