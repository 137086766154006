import { apiClient } from 'api/apiClient/apiClient'
import authStore from 'store/auth/auth'
import sentry from 'utils/sentry/sentry'

import { snapshot } from 'valtio'

import indexRoutes from 'routes/index.routes'

const MIN_REQUEST_DELAY = 60 * 1000
const rateLimit: Record<string, number> = {}

export async function postNavigateToStat(): Promise<void> {
  const route = location?.pathname?.split(`/`)?.[1] ?? ``
  if (route) {
    await postStat(route, `navigate-to`)
  }
}

export async function postStat(label: string, action: string): Promise<void> {
  const snap = snapshot(authStore)
  const module = window.location?.pathname?.split(`/`)?.[1]
  const now = +new Date()
  const system = snap.activeSystem?.id ?? null
  const key = `${system ?? `noSystem`}.${module}.${label}.${action}`

  // Index routes are unauthenticated routes, skipping stat
  if (indexRoutes.find(indexRoute => indexRoute.path?.startsWith(`/${module}`))) {
    return
  }

  if (rateLimit[key] && now - rateLimit[key] < MIN_REQUEST_DELAY) {
    return
  }
  rateLimit[key] = now

  const time_since_login = Math.round((now - snap.loginTimestamp) / 1000)
  const data = {
    module,
    label,
    action,
    time_since_login,
    user: snap.user?.id,
    system: snap.activeSystem?.id ?? null,
  }
  try {
    await apiClient(`events`, { data, method: `POST` })
    
  } catch (error: unknown) {
    sentry.captureException(error as Error)
  }
}
