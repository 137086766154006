import { apiClient } from 'api/apiClient/apiClient'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import { Serie } from 'ui/components/BaseChart/types'

import { Moment } from 'moment'
import { useQueries, UseQueryOptions, UseQueryResult, useQuery } from 'react-query'

import { sortSeriesByStackOrder } from 'helpers/chart.helper/chart.helper'

export type OperandObject = { first_operand: number | null; second_operand: number | null }
export type DataSourceValue = number | OperandObject | null

export type Timestep = `hour` | `day` | `month` | `year` | `period`

export type NodeSeries = { system: number; node: number; name: string; series: Serie[] }
export type SeriesGroupSeriesResponse = NodeSeries[]

export type TimeValues = { time: string; values: Record<string, DataSourceValue> }
export type PlanMeasDataResponse = { plan: TimeValues[]; meas: TimeValues[] }
export type TimeValuesDataResponse = TimeValues[]

export type PlanMeasGraphType = `PROD_REG` | `FOL`

export type GraphFilterType = PlanMeasGraphType | `FOL_FOR` | `PROD_FOR` | `DASH_KPI` | `WH_COLLAB` | `UNIT_AVAIL`

export type PlanMeasGroupType = `production_plan_regular` | `followup`
export type GroupType =
  | PlanMeasGroupType
  | `followup_forecast`
  | `production_plan_forecasts`
  | `dashboard_kpi`
  | `wastheat_collaboration`
  | `unit_availability`

type SeriesGroupInfo = DisplayNameObject & {
  id: number
  tooltip_text: string | null
  tooltip_text_sv: string | null
  tooltip_text_en: string | null
}

type SystemPartitioning = {
  id: number
  name: string
}

export type SeriesGroup = {
  id: number
  group_type: GroupType
  unit_name: string | null
  node: number
  unit: number | null
  system: number
  system_name: string
  info: SeriesGroupInfo | null
  tab_order: number
  system_partitioning: SystemPartitioning | null
}

export type SeriesGroupDataResponse<T extends GraphFilterType = GraphFilterType> = T extends PlanMeasGraphType
  ? PlanMeasDataResponse
  : TimeValuesDataResponse

type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T

export function useQueriesTyped<TQueries extends readonly UseQueryOptions[]>(
  queries: [...TQueries]
): {
  [ArrayElement in keyof TQueries]: UseQueryResult<
    TQueries[ArrayElement] extends { select: infer TSelect }
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        TSelect extends (data: any) => any
        ? ReturnType<TSelect>
        : never
      : Awaited<ReturnType<NonNullable<Extract<TQueries[ArrayElement], UseQueryOptions>[`queryFn`]>>>
  >
} {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useQueries(queries as UseQueryOptions<unknown, unknown, unknown>[]) as any
}

export const SERIES_GROUP_QUERY_KEY = `seriesGroup`
export const SERIES_GROUP_SERIES_QUERY_KEY = `seriesGroupSeries`

export function useSeriesGroups(type: GraphFilterType, queryConfig?: UseQueryOptions<SeriesGroup[], Error>) {
  const { systemId } = useAuth()
  const params = {
    system: systemId,
    group_type: type,
  }
  return useQuery(
    [SERIES_GROUP_QUERY_KEY, params],
    () =>
      apiClient<SeriesGroup[]>(`series_groups`, {
        params,
      }),
    { enabled: !!systemId && (queryConfig?.enabled !== undefined ? queryConfig.enabled : true) }
  )
}

export function useSeriesGroupSeriesById(
  id?: number, 
  queryConfig?: UseQueryOptions<Serie[], Error, Serie[], [string, number | undefined]>
) {
  return useQuery(
    [SERIES_GROUP_SERIES_QUERY_KEY, id],
    () => apiClient<Serie[]>(`series_groups/${id}/series`).then((series) => series.sort(sortSeriesByStackOrder)),
    {
      ...queryConfig,
      enabled: id !== undefined && queryConfig?.enabled !== undefined ? queryConfig.enabled : true,
      refetchOnWindowFocus: false,
    }
  )
}

export const SERIES_GROUP_DATA_QUERY_KEY = `seriesGroupData`

type SeriesGroupByDataIdQueryKeyObject = {
  id?: number
  start_time?: string
  end_time?: string
  timeStep?: Timestep
  groupType: GraphFilterType
}
export function useSeriesGroupDataById<
  Type extends PlanMeasDataResponse | TimeValuesDataResponse = PlanMeasDataResponse | TimeValuesDataResponse
>({
  id,
  startTime,
  endTime,
  timeStep = `hour`,
  groupType,
  queryConfig,
}: {
  id?: number
  startTime?: Moment
  endTime?: Moment
  groupType: GraphFilterType
  timeStep?: Timestep
  queryConfig?: UseQueryOptions<
    Type, 
    Error, 
    Type, 
    [string, SeriesGroupByDataIdQueryKeyObject]
  >
}) {
  return useQuery(
    [
      SERIES_GROUP_DATA_QUERY_KEY,
      {
        id: id,
        start_time: startTime?.format(),
        end_time: endTime?.format(),
        timeStep,
        groupType,
      },
    ],
    () =>
      apiClient<Type>(`series_groups/${id}/data`, {
        params: { start_time: startTime?.format(), end_time: endTime?.format(), timestep: timeStep },
      }),
    {
      ...queryConfig,
      enabled:
        id !== undefined &&
        !!startTime &&
        !!endTime &&
        (queryConfig?.enabled !== undefined ? queryConfig.enabled : true),
    }
  )
}
