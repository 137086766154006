import React, { ReactElement, useCallback, useContext, useMemo, useState } from 'react'

import { AlertVariant } from 'ui/components/Alert/Alert'

import { SnackbarProps } from '@mui/material'

type AlertContextType = {
  alert?: Alert
  error: (message: string, props?: Partial<SnackbarProps> | undefined) => void
  success: (message: string, props?: Partial<SnackbarProps> | undefined) => void
  warning: (message: string, props?: Partial<SnackbarProps> | undefined) => void
  info: (message: string, props?: Partial<SnackbarProps> | undefined) => void
  alertClear: () => void
}

const AlertContext = React.createContext<AlertContextType | undefined>(undefined)

type AlertProviderProps = { children: React.ReactNode }
export type Alert = { variant: AlertVariant; message: string; props?: Partial<SnackbarProps> }

export function AlertProvider({ children }: AlertProviderProps): ReactElement {
  const [alert, setAlert] = useState<Alert>()

  const error = useCallback(
    (message: string, props?: Partial<SnackbarProps>) => setAlert({ variant: `error`, message: message, props: props }),
    []
  )
  const success = useCallback(
    (message: string, props?: Partial<SnackbarProps>) =>
      setAlert({ variant: `success`, message: message, props: props }),
    []
  )
  const warning = useCallback(
    (message: string, props?: Partial<SnackbarProps>) =>
      setAlert({ variant: `warning`, message: message, props: props }),
    []
  )
  const info = useCallback(
    (message: string, props?: Partial<SnackbarProps>) => setAlert({ variant: `info`, message: message, props: props }),
    []
  )
  const alertClear = useCallback(() => setAlert(undefined), [])

  const value = useMemo(
    () => ({ alert, error, success, warning, info, alertClear }),
    [alert, error, success, warning, info, alertClear]
  )
  return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
}

export function useAlert(): AlertContextType {
  const context = useContext(AlertContext)
  if (context === undefined) {
    throw new Error(`useAlert must be used within \`AlertProvider\``)
  }
  return context
}
