import React from 'react'

import Tooltip from 'ui/components/Tooltip/Tooltip'

import { IconButton } from '@mui/material'

import styles from './StepButton.module.less'

export interface StepButtonProps {
  onClick: () => void;
  isDisabled?: boolean;
  tooltip: string;
  icon: React.ReactNode;
  testId?: string;
}

export function StepButton(props: StepButtonProps): JSX.Element {
  const { onClick, isDisabled, tooltip } = props

  return (
    <Tooltip title={tooltip} arrow placement="top">
      <IconButton
        disabled={isDisabled}
        onClick={onClick}
        className={styles.StepButton}
        data-testid={props.testId}
      >
        {props.icon}
      </IconButton>
    </Tooltip>
  )
}