import { apiClient } from 'api/apiClient/apiClient'
import { getOptJobLatestKey, OptJobStatus, OptJobUnitSetting } from 'api/optJobs/optJobs.api'
import { SeriesGroup, SeriesGroupDataResponse } from 'api/seriesGroups/seriesGroups.api'
import { useAuth } from 'ui/components/AuthContext/AuthContext'
import { Serie } from 'ui/components/BaseChart/types'

import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query'

import { sortSeriesByStackOrder } from 'helpers/chart.helper/chart.helper'
import { queryClient } from 'helpers/queryClient'

export type OptProject = {
  id: number
  display_name: string
  created_by: number
  opt_model?: number
  digital_twin?: number
  system: number
  cloned_from: number | null
  start_time: string
  end_time: string
  created_at: string
  updated_at: string
  project_type: string
  include_deviations: boolean
  status: OptJobStatus
  opt_jobs: number[] | null
  series_groups?: SeriesGroup[]
  series?: Record<number, Serie[]>
  data?: Record<number, SeriesGroupDataResponse[]> | null
}

type ProjectInputData = {
  unit_settings?: Record<string, OptJobUnitSetting>
}

export type OptProjectCreateData = Pick<
  OptProject,
  `display_name` | `start_time` | `end_time` | `project_type` | `include_deviations` | `system`
> & { id?: number }

export const OPT_PROJECTS_QUERY_KEY = `optProjects`
type GetOptProjectsKeyParams = {
  start_time: string
  end_time: string
}

function getOptProjectsKey(
  { system }: { system?: number }, 
  params?: GetOptProjectsKeyParams
) : [string, { system?: number }, GetOptProjectsKeyParams | undefined] {
  return [
    OPT_PROJECTS_QUERY_KEY,
    {
      system,
    },
    params,
  ]
}

function getOptProjectKey({ id }: { id?: number }) {
  return [OPT_PROJECTS_QUERY_KEY, id]
}

export function useOptProject({ id }: { id?: number }) {
  return useQuery(
    getOptProjectKey({ id }),
    async () => {
      const optProject = await apiClient<OptProject>(`opt_projects/${id}`)

      Object.entries(optProject.series || ({} as Record<number, Serie[]>)).forEach(([id, series]) => {
        if (optProject.series) {
          optProject.series[parseInt(id)] = series.sort(sortSeriesByStackOrder)
        }
      })

      return optProject
    },
    {
      enabled: !!id,
    }
  )
}

export function useOptProjects({
  start_time,
  end_time,
  queryConfig,
}: {
  start_time: string
  end_time: string
  queryConfig?: UseQueryOptions<
    OptProject[], 
    Error, 
    OptProject[], 
    [string, { system?: number }, GetOptProjectsKeyParams | undefined]
  >
}) {
  const { systemId } = useAuth()

  return useQuery(
    getOptProjectsKey({ system: systemId }, { start_time, end_time }),
    async () => {
      let optProjects = await apiClient<OptProject[]>(`opt_projects`, {
        params: {
          system: systemId,
          updated_at__lte: end_time,
          updated_at__gte: start_time,
        },
      })

      optProjects = optProjects.map((optProject) => {
        Object.entries(optProject.series || ({} as Record<number, Serie[]>)).forEach(([id, series]) => {
          if (optProject.series) {
            optProject.series[parseInt(id)] = series.sort(sortSeriesByStackOrder)
          }
        })

        return optProject
      })

      return optProjects
    },
    { ...queryConfig, enabled: !!systemId && (queryConfig?.enabled !== undefined ? queryConfig.enabled : true) }
  )
}
export function useOptProjectsMutation() {
  const createOptProject = (data: OptProjectCreateData) => apiClient<OptProject>(`opt_projects`, { data })

  const copyProject = ({ id, display_name, include_deviations }: { id: number; display_name: string, include_deviations: boolean }) =>
    apiClient<OptProject>(`opt_projects/${id}/copy`, { method: `PATCH`, data: { display_name: display_name, include_deviations: include_deviations } })

  const createOrCopyOptProject = ({ data }: { data: OptProjectCreateData }) => {
    if (data.id) {
      return copyProject({ id: data.id, display_name: data.display_name, include_deviations: data.include_deviations })
    }

    return createOptProject(data)
  }

  const queryClient = useQueryClient()

  return useMutation(createOrCopyOptProject, {
    onSuccess: () => {
      queryClient.invalidateQueries(OPT_PROJECTS_QUERY_KEY)
    },
  })
}

export async function setOptProjectsQueryData({
  projectId,
  system,
  status,
}: {
  projectId: number
  system?: number
  status: OptJobStatus
}) {
  const queryKey = getOptProjectsKey({ system })
  await queryClient.cancelQueries(queryKey)

  const previousProjects = queryClient.getQueryData<OptProject[] | null>(queryKey)

  queryClient.setQueryData<OptProject[] | null | undefined>(queryKey, (data) =>
    data?.map((project) => (projectId === project?.id ? { ...project, status: status } : project))
  )

  return () => queryClient.setQueryData(queryKey, previousProjects)
}

export function useOptProjectsDeleteMutation() {
  const queryClient = useQueryClient()

  return useMutation((id: number) => apiClient<{ id: number }>(`opt_projects/${id}`, { method: `DELETE` }), {
    onSuccess: () => {
      queryClient.invalidateQueries(OPT_PROJECTS_QUERY_KEY)
    },
  })
}
export function useOptProjectOptimizeMutation() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, inputData }: { id: number; inputData?: ProjectInputData }) =>
      apiClient(`opt_projects/${id}/optimize`, { method: `PATCH`, data: { params: { input_data: inputData } } }),
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries(OPT_PROJECTS_QUERY_KEY)
        queryClient.invalidateQueries(
          getOptJobLatestKey({
            sandboxProjectId: id,
            type: 'sandbox',
          }),
          { refetchInactive: true }
        )
      },
    }
  )
}
