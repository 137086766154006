import React from 'react'

import { useAuth } from 'ui/components/AuthContext/AuthContext'
import { RoutesArray } from 'ui/components/Navigation/types'

import { useTranslation } from 'react-i18next'

const StartPageView = React.lazy(() => import('views/StartpageView/StartpageView'))
const OptimizeView = React.lazy(() => import('views/OptimizeView/OptimizeView'))
const OptimizeSettingsView = React.lazy(() => import('views/OptimizeSettingsView/OptimizeSettingsView'))
const OptimizeModelSettingsView = React.lazy(() => import('views/OptimizeModelSettingsView/OptimizeModelSettingsView'))
const FollowupProductionView = React.lazy(() => import('views/FollowupProductionView/FollowupProductionView'))
const ReviewForecastsView = React.lazy(() => import('views/ReviewForecastsView/ReviewForecastsView'))
const ShiftHandoverView = React.lazy(() => import('views/ShiftHandoverView/ShiftHandoverView'))
const DevView = React.lazy(() => import('views/DevView/DevView'))
const AbSLabsView = React.lazy(() => import('views/AbSLabsView/AbSLabsView'))
const DigitalTwinSamEnergiView = React.lazy(() => import('views/DigitalTwinSamEnergiView/DigitalTwinSamEnergiView'))
const SamEnergiView = React.lazy(() => import('views/SamEnergiView/SamEnergiView'))
const AvailabilityView = React.lazy(() => import('views/AvailabilityView/AvailabilityView'))
const SandboxProjectView = React.lazy(() => import('views/SandboxProjectView/SandboxProjectView'))
const SandboxView = React.lazy(() => import('views/SandboxView/SandboxView'))
const SettlementsView = React.lazy(() => import('views/SettlementsView/SettlementsView'))
const ReferenceGuidesView = React.lazy(() => import('views/ReferenceGuidesView/ReferenceGuidesView'))
const DigitalTwinVersionControlView = React.lazy(() => import('views/VersionControlView/DigitalTwinVersionControlView'))
const DigitalTwinDeviationSettingsView = React.lazy(
  () => import('views/DigitalTwinSettingsView/DigitalTwinDeviationSettingsView')
)
const DigitalTwinBaseSettingsView = React.lazy(
  () => import('views/DigitalTwinSettingsView/DigitalTwinBaseSettingsView')
)
const ElectricityPlanningView = React.lazy(() => import('views/ElectricityPlanningView/ElectricityPlanningView'))
const ElectricityPlanningLightView = React.lazy(
  () => import('views/ElectricityPlanningLightView/ElectricityPlanningLightView')
)
const FuelPlanView = React.lazy(() => import('views/FuelPlanView/FuelPlanView'))
const UserProfileView = React.lazy(() => import('views/UserProfileView/UserProfileView'))
const AdminSettingsView = React.lazy(() => import('views/AdminSettingsView/AdminSettingsView'))
const UiConfigView = React.lazy(() => import('views/UiConfigView/UiConfigView'))
const MigrateToDbSView = React.lazy(() => import('views/MigrateToDbSView/MigrateToDbSView'))
const UnitAvailabilityView = React.lazy(() => import('views/UnitAvailabilityView/UnitAvailabilityView'))
const LogBookView = React.lazy(() => import('views/LogBookView/LogBookView'))
const ShiftScheduleView = React.lazy(() => import('views/ShiftScheduleView/ShiftScheduleView'))
const EventsView = React.lazy(() => import('views/EventsView/EventsView'))
const OptimizationAnalysisView = React.lazy(() => import('views/OptimizationAnalysisView/OptimizationAnalysisView'))

export function useMainRoutes(): RoutesArray {
  const { t } = useTranslation()
  const { activeSystem } = useAuth()
  const { user } = useAuth()

  return [
    {
      path: `/startpage`,
      sidebarName: t(`Startpage`),
      navbarName: t(`Startpage`),
      module: `startpage`,
      icon: `fal fa-home`,
      component: StartPageView,
    },
    {
      path: `/optimizationanalysis`,
      notInSidemenu: true,
      module: `optimizationanalysis`,
      component: OptimizationAnalysisView,
    },
    {
      path: `/optimize`,
      sidebarName: t(`Production plan`),
      navbarName: t(`Production plan`),
      module: `optimize`,
      icon: `fal fa-chart-area`,
      component: OptimizeView,
    },
    {
      sidebarName: t(`Settings`),
      icon: `fal fa-cogs`,
      module: `settings`,
      children: [
        {
          path: activeSystem?.primary_digital_twin ? `/digitalTwinDeviationSettings` : `/optimizesettings`,
          sidebarName: t(`Deviation settings`),
          navbarName: t(`Deviation settings`),
          module: `optimizesettings`,
          icon: `fal fa-wrench`,
          component: activeSystem?.primary_digital_twin ? DigitalTwinDeviationSettingsView : OptimizeSettingsView,
        },
        {
          path: activeSystem?.primary_digital_twin ? `/digitalTwinBaseSettings` : `/optimizemodelsettings`,
          sidebarName: t(`Opt model settings`),
          navbarName: t(`Opt model settings`),
          module: `optimizemodelsettings`,
          icon: `fal fa-cog`,
          component: activeSystem?.primary_digital_twin ? DigitalTwinBaseSettingsView : OptimizeModelSettingsView,
        },
      ],
    },
    {
      sidebarName: t(`Followup`),
      icon: `fal fa-history`,
      module: `followup`,
      children: [
        {
          path: `/followupproduction`,
          sidebarName: t(`Followup: Production`),
          sidebarNameAsChild: t(`Production`),
          navbarName: t(`Followup: Production`),
          module: `followupproduction`,
          icon: `fal fa-chart-bar`,
          component: FollowupProductionView,
        },
        {
          sidebarName: t(`Review forecasts`),
          sidebarNameAsChild: t(`Review forecasts`),
          navbarName: t(`Review forecasts`),
          path: `/reviewforecasts`,
          module: `reviewforecasts`,
          icon: `fal fa-chart-line`,
          component: ReviewForecastsView,
        },
      ],
    },
    {
      path: `/events`,
      sidebarName: t('Events'),
      navbarName: t('Events'),
      module: `events`,
      icon: `fal fa-calendar-week`,
      component: EventsView,
    },
    {
      path: `/logbook`,
      sidebarName: t(`Logbook`),
      navbarName: t(`Logbook`),
      module: `logbook`,
      icon: `fal fa-sticky-note`,
      component: LogBookView,
    },
    {
      sidebarName: t(`Shift`),
      icon: `fal fa-calendar-alt`,
      module: `shifthandover`,
      children: [
        {
          path: `/shifthandover`,
          sidebarName: t(`Shift handover`),
          navbarName: t(`Shift handover`),
          module: `shifthandover`,
          icon: `fal fa-clipboard-list-check`,
          component: ShiftHandoverView,
        },
      ],
    },
    {
      path: `/shiftschedule`,
      sidebarName: t(`Shift Schedule`),
      navbarName: t(`Shift Schedule`),
      module: `shiftschedule`,
      icon: `fal fa-user-clock`,
      component: ShiftScheduleView,
    },
    {
      path: `/dev`,
      module: `devView`,
      component: DevView,
      notInSidemenu: true,
      alwaysAvailable: true,
    },
    {
      path: `/labs`,
      module: `abSLabsView`,
      component: AbSLabsView,
      notInSidemenu: true,
      alwaysAvailable: true,
    },
    {
      path: `/samenergi`,
      sidebarName: t(`SamEnergi`),
      navbarName: t(`SamEnergi`),
      module: `samenergi`,
      icon: `fal fa-handshake`,
      component: activeSystem?.primary_digital_twin ? DigitalTwinSamEnergiView : SamEnergiView,
    },
    {
      path: `/availability`,
      sidebarName: t(`Availability`),
      navbarName: t(`Availability`),
      module: `availability`,
      icon: `fal fa-percentage`,
      component: activeSystem?.primary_opt_model ? AvailabilityView : UnitAvailabilityView,
    },
    {
      path: `/sandbox/:id/`,
      navbarName: t(`Sandbox`),
      module: `sandbox`,
      notInSidemenu: true,
      alwaysAvailable: true,
      component: SandboxProjectView,
    },
    {
      path: `/sandbox`,
      sidebarName: t(`Sandbox`),
      navbarName: t(`Sandbox`),
      module: `sandbox`,
      icon: `fal fa-flask`,
      component: SandboxView,
    },
    {
      path: `/settlements`,
      sidebarName: t(`Settlements`),
      navbarName: t(`Settlements`),
      module: `settlements`,
      icon: `far fa-calculator-alt`,
      component: SettlementsView,
    },
    {
      path: `/referenceguides`,
      sidebarName: t(`Reference guides`),
      navbarName: t(`Reference guides`),
      module: `referenceguides`,
      icon: `fal fa-book`,
      component: ReferenceGuidesView,
    },
    {
      component: DigitalTwinVersionControlView,
      icon: `fal fa-user-friends`,
      module: `digitaltwinversioncontrol`,
      navbarName: t(`Version Control Digital Twin`),
      path: `/digitalTwinVersionControl`,
      sidebarName: t(`Version Control Digital Twin`),
    },
    {
      path: `/electricityplanning`,
      sidebarName: t(`Electricity Planning`),
      navbarName: t(`Electricity Planning`),
      module: `elplan`,
      icon: `far fa-bolt`,
      component: ElectricityPlanningView,
    },
    {
      path: `/electricityplanninglight`,
      sidebarName: user?.is_superuser ? t(`Electricity Planning Light`) : t(`Electricity Planning`),
      navbarName: user?.is_superuser ? t(`Electricity Planning Light`) : t(`Electricity Planning`),
      module: `elplanlight`,
      icon: `far fa-bolt`,
      component: ElectricityPlanningLightView,
    },
    {
      path: `/fuelplan`,
      module: `fuelplan`,
      sidebarName: t('Fuel plan'),
      navbarName: t('Fuel plan'),
      component: FuelPlanView,
      icon: `fal fa-mountains`,
    },
    {
      path: `/userprofile`,
      module: `userpage`,
      component: UserProfileView,
      notInSidemenu: true,
      alwaysAvailable: true,
    },
    {
      path: `/adminsettings`,
      module: `useradmin`,
      component: AdminSettingsView,
      globalModule: true,
      notInSidemenu: true,
    },
    {
      path: `/uiconfig`,
      module: `uiconfig`,
      component: UiConfigView,
      notInSidemenu: true,
    },
    {
      path: `/migratetodbs`,
      module: `uiconfig`,
      component: MigrateToDbSView,
      notInSidemenu: true,
    },
    {
      module: `startpage`,
      redirect: true,
      path: `/`,
      to: `/startpage`,
    },
  ]
}
