import Datetime from 'utils/datetime/datetime'

import { proxy, snapshot } from 'valtio'
import { subscribeKey } from 'valtio/utils'

type FuelPlanViewStore = {
  zoomResolutionSwitch: `day` | `week` | 'month'
}

function initializeFuelPlanViewStore(): FuelPlanViewStore {
  const zoomResolutionSwitch = (localStorage.getItem(`fuelPlanZoomResolutionSwitch`) as `day` | `week` | 'month') ?? `month`
  return { zoomResolutionSwitch }
}

const fuelPlanViewStore = proxy<FuelPlanViewStore>(initializeFuelPlanViewStore())

subscribeKey(fuelPlanViewStore, `zoomResolutionSwitch`, (zoomResolution) => {
  localStorage.setItem(`fuelPlanZoomResolutionSwitch`, zoomResolution)
})

export function fuelPlanViewRange(zoomResolution?: 'day' | 'week' | 'month'): [ISODateTime, ISODateTime] {
  if (zoomResolution === undefined) {
    const snap = snapshot(fuelPlanViewStore)
    zoomResolution = snap.zoomResolutionSwitch
  }

  const todayDate = Datetime.getTodayDate()

  if (zoomResolution === `day`) {
    return [todayDate.startTime, todayDate.endTime]
  }

  if (zoomResolution === `week`) {
    return [todayDate.startTime, Datetime.getDayAfterDate(Datetime.getISONow(169)).endTime]
  }
  if (zoomResolution === `month`) {
    return [todayDate.startTime, Datetime.getDayAfterDate(Datetime.getISONow(720)).endTime]
  }

  return [todayDate.startTime, Datetime.getDayAfterDate(Datetime.getISONow(720)).endTime]
}

export default fuelPlanViewStore
