import { colors } from 'styles/variables'

import { t } from 'i18next'

import { getCurrency } from 'helpers/global.helper/global.helper'

import { ChartConfigWithZoom, chartColors } from './chartTypes'

export const DEFAULT_HEIGHT = 300

export const BASE_CONFIG: ChartConfigWithZoom = {
  type: `line`,
  defaults: {
    font: {
      family: `Open Sans`,
    },
  },
  data: {
    labels: [],
    datasets: [],
  },
  options: {
    animation: false,
    responsive: true,
    spanGaps: false,
    maintainAspectRatio: false,
    normalized: true,
    elements: {
      point: {
        radius: 0,
        hitRadius: 0,
      },
    },
    interaction: {
      mode: `index`,
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      chartLegend: {
        display: true,
      },
      todayMarker: {
        enabled: true,
      },
      exportButton: {
        display: true,
      },
      syncYAxis: {
        enabled: true,
      },
      tooltip: {
        enabled: false,
        callbacks: {},
        external: () => undefined,
      },
      crosair: {
        enabled: true,
      },
      filler: {
        propagate: true,
      },
      zoom: {
        limits: {
          x: {
            min: 'original',
            max: 'original',
          },
        },
        zoom: {
          drag: {
            enabled: true,
            backgroundColor: chartColors.zoomBoxBackground,
            borderColor: chartColors.zoomBoxBorder,
            borderWidth: 2,
          },
          wheel: {
            enabled: false,
          },
          pinch: {
            enabled: false,
          },
          mode: 'x',
        },
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          tickBorderDash: [2, 5],
          color: colors.gridLines,
        },
        border: {
          dash: [2, 5],
        },
        ticks: {
          color: colors.black,
          maxRotation: 0,
          minRotation: 30,
          autoSkip: false,
          font: {
            size: 14,
          },
          sampleSize: 1,
          autoSkipPadding: 30,
        },
      },
      y: {
        border: {
          dash: [2, 5],
        },
      },
    },
  },
}

export const yAxisGridSettingsDisplayFalse = {
  borderColor: '$grey',
  tickWidth: 2,
  borderWidth: 2,
  borderDash: [3, 5],
  display: false,
}

export const yAxisGridSettingsDisplayTrue = {
  borderColor: '$grey',
  tickWidth: 2,
  borderWidth: 2,
  borderDash: [3, 5],
  display: true,
}

export const xAxisGridSettings = {
  borderColor: '$grey',
  tickWidth: 2,
  borderWidth: 2,
}

export const DEFAULT_CONFIG: Partial<ChartConfigWithZoom> = {
  options: {
    scales: {
      y: {
        display: true,
        title: { text: ``, display: false },
        grid: yAxisGridSettingsDisplayTrue,
        border: {
          dash: [3, 5],
        },
      },
      yRight: {
        display: false,
        position: `right`,
        title: { text: ``, display: false },
        grid: yAxisGridSettingsDisplayFalse,
        border: {
          dash: [3, 5],
        },
      },
    },
    plugins: {
      chartLegend: {
        shared: false,
        display: true,
        groupId: ``,
      },
      todayMarker: {
        enabled: false,
      },
    },
  },
}

export const DEFAULT_TIME_SERIES_CONFIG: Partial<ChartConfigWithZoom> = {
  options: {
    scales: {
      y: {
        display: true,
        title: { text: `MWh`, display: true },
        grid: yAxisGridSettingsDisplayTrue,
        border: {
          dash: [3, 5],
        },
      },
      yRight: {
        display: true,
        position: `right`,
        title: { text: `${t(`Setting`)}`, display: true },
        grid: yAxisGridSettingsDisplayFalse,
        border: {
          dash: [3, 5],
        },
      },
    },
    plugins: {
      chartLegend: {
        shared: false,
        display: true,
        groupId: ``,
      },
      todayMarker: {
        enabled: false,
      },
    },
  },
}

export const DEFAULT_PROD_PLAN_CONFIG: Partial<ChartConfigWithZoom> = {
  options: {
    scales: {
      y: {
        title: {
          text: `MW`,
          display: true,
        },
        grid: yAxisGridSettingsDisplayTrue,
        border: {
          dash: [3, 5],
        },
        display: true,
        suggestedMin: 0,
      },
      yAcc: {
        grid: yAxisGridSettingsDisplayFalse,
        title: {
          text: `MWh`,
          display: true,
        },
        display: true,
        position: `right`,
        suggestedMin: 0,
      },
      yCost: {
        grid: yAxisGridSettingsDisplayFalse,
        title: {
          text: getCurrency(),
          display: false,
        },
        display: false,
        position: `right`,
        ticks: {
          precision: 0,
        },
      },
      x: {
        grid: xAxisGridSettings,
      },
    },
    plugins: {
      chartLegend: {
        display: true,
        shared: false,
        groupId: `productionPlan`,
      },
      todayMarker: {
        enabled: true,
      },
      exportButton: {
        display: true,
      },
    },
  },
}

export const DEFAULT_FORECAST_CONFIG: Partial<ChartConfigWithZoom> = {
  options: {
    scales: {
      y: {
        title: {
          text: `MW`,
          display: true,
        },
        grid: yAxisGridSettingsDisplayTrue,
        border: {
          dash: [3, 5],
        },
        display: true,
        suggestedMin: 0,
      },
      yAcc: {
        grid: yAxisGridSettingsDisplayFalse,
        title: {
          text: `MWh`,
          display: true,
        },
        display: false,
        position: `right`,
        suggestedMin: 0,
      },
      yCost: {
        grid: yAxisGridSettingsDisplayFalse,
        title: {
          text: getCurrency(),
          display: false,
        },
        display: false,
        position: `right`,
        ticks: {
          precision: 0,
        },
      },
      x: {
        grid: xAxisGridSettings,
      },
    },
    plugins: {
      chartLegend: {
        display: true,
        shared: false,
        groupId: `productionPlan`,
      },
      todayMarker: {
        enabled: true,
      },
      exportButton: {
        display: true,
      },
    },
  },
}