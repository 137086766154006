import Datetime from 'utils/datetime/datetime'

import { proxy, snapshot } from 'valtio'

import { getRoute } from 'helpers/route.helper/route.helper'
import { fuelPlanViewRange } from 'views/FuelPlanView/store/FuelPlanStore'
import { optimizeViewRange } from 'views/OptimizeView/store/optimizeViewStore'

type RouteZoom = {
  startTime: ISODateTime | null
  endTime: ISODateTime | null
}

type ZoomStore = {
  route: string
  zoom: Record<string, RouteZoom>
  startTime: ISODateTime | null
  endTime: ISODateTime | null
}

const onUpdateSubscribers: Record<string, () => void> = {}

const zoomStore = proxy<ZoomStore>({
  route: window.location?.pathname?.split(`/`)?.[1] ?? ``,
  zoom: {
    optimize: getDefaultTimes(`optimize`),
    fuelplan: getDefaultTimes(`fuelplan`),
  },
  get startTime() {
    return this.zoom[this.route]?.startTime ?? null
  },
  get endTime() {
    return this.zoom[this.route]?.endTime ?? null
  },
})

export function subscribeOnUpdate(id: string, callback: () => void): void {
  onUpdateSubscribers[id] = callback
}

function onUpdate(): void {
  Object.values(onUpdateSubscribers).forEach((callback) => callback())
}

export function getDefaultTimes(route: string): RouteZoom {
  const defaultZoomes: Record<string, RouteZoom> = {
    optimize: {
      startTime: Datetime.getISONow(0),
      endTime: Datetime.getISONow(169),
    },
    fuelplan: {
      startTime: Datetime.getISONow(0),
      endTime: Datetime.getISONow(720),
    },
  }

  if (route === 'optimize') {
    const [startTime, endTime] = optimizeViewRange()
    defaultZoomes.optimize = { startTime, endTime }
  }

  if (route === 'fuelplan') {
    const [startTime, endTime] = fuelPlanViewRange()
    defaultZoomes.fuelplan = { startTime, endTime }
  }

  return (
    defaultZoomes[route] ?? {
      startTime: null,
      endTime: null,
    }
  )
}

export function resetZoom(): void {
  const route = getRoute()
  zoomStore.zoom[route] = getDefaultTimes(route)
  onUpdate()
}

export function resetZoomOnAllRoutes(): void {
  const zoomSnap = snapshot(zoomStore)

  Object.keys(zoomSnap.zoom).forEach((route) => {
    zoomStore.zoom[route] = getDefaultTimes(route)
  })

  onUpdate()
}

export function setZoom(startTime: ISODateTime | null, endTime: ISODateTime | null, route?: string): void {
  const zoomSnap = snapshot(zoomStore)
  const zoom = {
    startTime: startTime,
    endTime: endTime,
  }

  zoomStore.zoom = {
    ...zoomSnap.zoom,
    [route ?? zoomSnap.route]: zoom,
  }

  onUpdate()
}

export default zoomStore
