import { apiClient } from 'api/apiClient/apiClient'
import { useAuth } from 'ui/components/AuthContext/AuthContext'

import { usePusher } from '@harelpls/use-pusher'
import { useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query'


import { EventCategory, EventPost, EventStatus } from 'views/EventsView/components/events.helper'

export const OPERATIONAL_EVENT_QUERY_KEY = `operational_events`

function useGetPusherClientSocketId(): { [key: string]: string } {
  const { client } = usePusher()
  const headers: { [key: string]: string } = {}
  if (client?.connection.socket_id) {
    headers['Ws-Socket'] = client.connection.socket_id
  }
  return headers
}

export type getAllEventMutationParams = {
  ids?: number[],
  object_category?: string
  object_name?: string
  attribute?: string
}

export function useGetAllEventMutation(): UseMutationResult<
  EventPost[],
  unknown,
  getAllEventMutationParams,
  unknown
  > {
  const { activeSystem } = useAuth()

  return useMutation((params: getAllEventMutationParams): Promise<EventPost[]> => {
    if (!activeSystem) {
      return Promise.resolve([])
    }

    return apiClient<EventPost[]>(`operational_events`, {
      method: 'GET',
      params: {system_id: activeSystem.id, ...params},
      addBracketsToArrayParamKeys: false,
    }
    )
  })
}

export function useGetAllEvents(system_id?: number, start_time?: ISODateTime, end_time?: ISODateTime, status?: EventStatus, ids?: number[]) {
  return useQuery([OPERATIONAL_EVENT_QUERY_KEY, system_id, start_time, end_time, status], async () => {
    const data = apiClient<EventPost[]>(`operational_events`, {
      method: 'GET',
      params: {
        ids,
        system_id,
        start_time,
        end_time,
        status,
      },
    })

    if (!data) {
      return []
    }
    return data
  },
  {
    enabled: system_id !== null && system_id !== undefined,
  })
}

export function useGetEvent(id?: number | null) {
  return useQuery([OPERATIONAL_EVENT_QUERY_KEY, id], async () => {
    const data = apiClient<EventPost>(`operational_events/${id}`, {
      method: 'GET',
    })

    if (!data) {
      return null
    }

    return data
  },
  {
    enabled: id !== null && id !== undefined,
  })
}

export function useEventCreateMutation() {
  const queryClient = useQueryClient()
  const clientSocketIdForHeader = useGetPusherClientSocketId()

  return useMutation(
    (data: EventPost) => apiClient<EventPost>(
      `operational_events/create`, 
      { data, method: `POST`, headers: clientSocketIdForHeader}
    ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(OPERATIONAL_EVENT_QUERY_KEY)
      },
    }
  )
}

export function useEventUpdateMutation() {
  const queryClient = useQueryClient()
  const clientSocketIdForHeader = useGetPusherClientSocketId()

  return useMutation(
    (data: EventPost) => apiClient<EventPost>(
      `operational_events/${data.id}/update`, 
      { data, method: `PATCH`, headers: clientSocketIdForHeader}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(OPERATIONAL_EVENT_QUERY_KEY)
      },
    }
  )
}

export function useEventDeleteMutation() {
  const queryClient = useQueryClient()
  const clientSocketIdForHeader = useGetPusherClientSocketId()

  return useMutation(
    (id: number) => apiClient<void>(
      `operational_events/${id}/delete`, 
      { method: `DELETE` , headers: clientSocketIdForHeader}
    ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(OPERATIONAL_EVENT_QUERY_KEY)
      },
    }
  )
}

export function useGetEventCategories(system_id?: number) {
  return useQuery([OPERATIONAL_EVENT_QUERY_KEY, system_id], async () => {
    const data = apiClient<EventCategory[]>(`operational_event_categories`, {
      method: 'GET',
      params: {
        system_id,
      },
    })

    if (!data) {
      return []
    }
    return data
  })
}

export function useGetAllEventTags(system_id?: number) {
  return useQuery([OPERATIONAL_EVENT_QUERY_KEY, system_id, 'tags'], async () => {
    const data = apiClient<string[]>(`operational_event_all_tags`, {
      method: 'GET',
      params: {
        system_id,
      },
    })

    if (!data) {
      return []
    }

    return data

  },
  {
    enabled: system_id !== null && system_id !== undefined,
  })
}