import React, { ReactElement, useState } from 'react'

import { useUserMutation } from 'api/users/users.api'
import { Button } from 'ui/atoms'
import { useAuth } from 'ui/components/AuthContext/AuthContext'

import i18n from 'i18next'

import styles from './LanguagePicker.module.less'

type Language = {
  code: LanguageCode
  label: string
}

const languages: Language[] = [
  { code: 'en', label: 'English' },
  { code: 'sv', label: 'Svenska' },
  { code: 'fi', label: 'Suomi' },
]

function LanguagePicker(): ReactElement {
  const { user } = useAuth()
  const { mutateAsync: updateUser } = useUserMutation()
  const [languageSelected, setLanguageSelected] = useState(i18n.language)
  
  const changeLanguage = async (lng: LanguageCode): Promise<void> => {
    await i18n.changeLanguage(lng)
    localStorage.setItem('language', lng)

    if (user) {
      user.settings = { ...user.settings, language: lng }
      await updateUser({ id: user.id, data: user })
    }
    setLanguageSelected(lng)

    // TODO: Fix needed. Force reload to apply new language for the dates
    setTimeout(() => {
      location.reload()
    }, 500)
  }

  return (
    <div className={styles.ProfileMenuItem_Root}>
      {languages.map((language) => (
        <Button
          primary={languageSelected === language.code}
          secondary={languageSelected !== language.code}
          marginRight
          key={language.code}
          className={styles.ProfileMenuItem_Item}
          onClick={() => changeLanguage(language.code)}
        >
          {language.label}
        </Button>
      ))}
    </div>
  )
}

export default LanguagePicker
