import { apiClient } from 'api/apiClient/apiClient'
import { postProcessDatasets } from 'api/dataset/dataset.api.helper'
import authStore from 'store/auth/auth'
import Datetime from 'utils/datetime/datetime'
import sentry from 'utils/sentry/sentry'

import { createClient } from '@sigholm/dbs-js-client'
import type { DataPoint as DbSDataPoint, Dataset as DbSDataset } from '@sigholm/dbs-js-client/lib/esm/index.d'
import { snapshot } from 'valtio'

import { addMissingDatasets } from 'helpers/dataset.helper/dataset.helper'
import { clone, isArray } from 'helpers/global.helper/global.helper'

import { serializeMetadataFilter } from './dbs.helper'

function getDbSStartAndEndTimeFromDatasetInstruction(datasetInstruction: DatasetInstruction): {
  startTime: string
  endTime: string
  createdAt?: string
  createdAtOperator?: string
} {
  const { filter } = datasetInstruction
  let startTime = filter.start_time as string
  let endTime = filter.end_time as string

  // Use offset if time is not present
  if (!startTime) {
    const offset = filter.offset_start_time || 0
    const negativeSign = offset < 0 ? '-' : ''
    const absOffset = Math.abs(offset)
    startTime = `${negativeSign}PT${absOffset}H`
  }
  if (!endTime) {
    const offset = filter.offset_end_time || 0
    const negativeSign = offset < 0 ? '-' : ''
    const absOffset = Math.abs(offset)
    endTime = `${negativeSign}PT${absOffset}H`
  }

  // Created at
  let createdAt: string | undefined = datasetInstruction.filter?.created_at
  let createdAtOperator: string | undefined = datasetInstruction.filter?.created_at_operator

  if (!createdAt) {
    createdAt = undefined
  }

  if (createdAt && !createdAtOperator) {
    createdAtOperator = 'lte'
  }

  return { startTime, endTime, createdAt, createdAtOperator }
}

function getDbSAggregateFunction(datasetInstruction: DatasetInstruction): string | undefined {
  if (!datasetInstruction.filter?.aggregate) {
    return undefined
  }

  // Do not aggregate in backend
  if (datasetInstruction.filter.aggregate_in_frontend) {
    return undefined
  }

  const aggregate = datasetInstruction.filter.aggregate
  const theSameAggregate = new Set(['latest', 'min', 'max', 'sum'])
  if (theSameAggregate.has(aggregate)) {
    return aggregate
  }

  if (aggregate === 'mean') {
    return 'avg'
  }

  throw new Error(
    `Inknown aggregation function: ${datasetInstruction.filter.aggregate} in dataset instruction: ${datasetInstruction}`
  )
}

export function filterInternalIdsByOnlyReturnIds(
  _datasetInstruction: Partial<DatasetInstruction>,
  onlyReturnIds: string[],
  returnIdRegexFilter?: string
): {
  datasetInstruction: Partial<DatasetInstruction>
  earlyReturn: boolean
} {
  // No filter to apply
  if (!onlyReturnIds.length && !returnIdRegexFilter) {
    return {
      datasetInstruction: _datasetInstruction,
      earlyReturn: false,
    }
  }

  // Not valid DbS dataset instruction
  if (!_datasetInstruction.contract?.metadata_filter?.include?.internal_id) {
    return {
      datasetInstruction: _datasetInstruction,
      earlyReturn: false,
    }
  }

  const datasetInstruction = clone(_datasetInstruction)

  // Filter by regex
  if (returnIdRegexFilter) {
    let allInternalIds: string[] = _datasetInstruction.contract.metadata_filter.include.internal_id
    if (typeof allInternalIds === 'string') {
      allInternalIds = [allInternalIds]
    }

    const regex = new RegExp(returnIdRegexFilter)

    const datasetContract = datasetInstruction.contract as DatasetContractDbS

    datasetContract.metadata_filter.include.internal_id = allInternalIds.filter((internalId) => {
      const returnId = (_datasetInstruction.contract.id_renaming || {})[internalId] || internalId

      if (isArray(returnId)) {
        for (let i = 0; i < returnId.length; i++) {
          if (regex.test(returnId[i])) {
            return true
          }
        }
      } else {
        return regex.test(returnId)
      }
    })

    // Filter id_renaming as well
    datasetContract.id_renaming = Object.entries(datasetContract.id_renaming || {}).reduce(
      (acc, [internalId, returnId]) => {
        const returnIds = isArray(returnId) ? returnId : [returnId]

        // Only keep the array if any returnId matches the regex
        const filteredReturnIds = returnIds.filter((id) => regex.test(id))

        if (filteredReturnIds.length) {
          acc[internalId] = filteredReturnIds
        }

        return acc
      },
      {} as Record<string, string | string[]>
    )
  }

  // Filter by onlyReturnIds
  if (onlyReturnIds.length) {
    const onlyReturnIdsSet = new Set(onlyReturnIds)
    let internalIds: string[] = datasetInstruction.contract.metadata_filter.include.internal_id

    if (typeof internalIds === 'string') {
      internalIds = [internalIds]
    }

    datasetInstruction.contract.metadata_filter.include.internal_id = internalIds.filter((internalId) => {
      const returnId = (datasetInstruction.contract.id_renaming || {})[internalId] || internalId

      if (isArray(returnId)) {
        for (let i = 0; i < returnId.length; i++) {
          if (onlyReturnIdsSet.has(returnId[i])) {
            return true
          }
        }
      } else {
        return onlyReturnIdsSet.has(returnId)
      }
    })
  }

  // Skip data fetching if all datasets are filtered away
  const earlyReturn = datasetInstruction.contract.metadata_filter.include.internal_id.length === 0

  if (earlyReturn) {
    console.debug(`Early returning due to filtering away all datasets`, {
      originalDatasetInstruction: _datasetInstruction,
      datasetInstruction,
      onlyReturnIds,
    })
  }

  return { datasetInstruction, earlyReturn }
}

let revalidateApiTokenStatus: '' | 'loading' | 'success' | 'error' = ''
let refreshedApiTokenAt: Date | null = null
let ongoingRequest: Promise<{ dbsApiToken: string; dbsGlobalReadApiToken: string }> | null = null
const maxRefreshRate = 1000 * 60 * 5 // 5 minutes

export async function revalidateApiToken(): Promise<{ dbsApiToken: string; dbsGlobalReadApiToken: string }> {
  if (revalidateApiTokenStatus === 'loading' && ongoingRequest) {
    return ongoingRequest
  }

  if (refreshedApiTokenAt) {
    const now = new Date()
    const diff = now.getTime() - refreshedApiTokenAt.getTime()
    if (diff < maxRefreshRate) {
      throw new Error(`DbS API Token was refreshed ${diff / 1000} seconds ago, skipping revalidation...`)
    }
  }

  revalidateApiTokenStatus = 'loading'
  ongoingRequest = (async () => {
    try {
      const newToken = await apiClient<{ dbs_api_token: string; dbs_global_read_api_token: string }>(
        'users/refresh_dbs_api_token',
        {
          method: 'POST',
        }
      )
      const dbsApiToken = newToken.dbs_api_token
      const dbsGlobalReadApiToken = newToken.dbs_global_read_api_token

      if (!authStore.user) {
        throw new Error(`No user found to set DbS API Token to.`)
      }

      authStore.user.dbs_api_token = dbsApiToken
      authStore.user.dbs_global_read_api_token = dbsGlobalReadApiToken
      refreshedApiTokenAt = new Date()
      revalidateApiTokenStatus = 'success'

      return { dbsApiToken, dbsGlobalReadApiToken }
    } catch (error: unknown) {
      sentry.captureException(error as Error)
      revalidateApiTokenStatus = 'error'
      throw error
    } finally {
      ongoingRequest = null
    }
  })()

  return ongoingRequest
}

export async function initDbSApiToken(user: User): Promise<void> {
  let dbsApiToken: string | undefined = user.dbs_api_token
  let dbsBaseUrl: string | undefined = user.dbs_url

  if (!dbsApiToken) {
    const authSnap = snapshot(authStore)
    dbsApiToken = authSnap.user?.dbs_api_token
    dbsBaseUrl = authSnap.user?.dbs_url
  }

  if (!dbsApiToken) {
    const data = await revalidateApiToken()
    dbsApiToken = data.dbsApiToken
  }

  const client = createClient({
    baseUrl: dbsBaseUrl,
    apiToken: dbsApiToken,
    maxRetryAttempts: 1,
    verbose: true,
  })

  try {
    const isOk = await client.ping()
    if (isOk) {
      return
    }
  } catch (error: unknown) {
    // Probably expired token
    // eslint-disable-next-line no-console
    console.warn(error)
  }

  try {
    const data = await revalidateApiToken()
    dbsApiToken = data.dbsApiToken

    /**
     * On Edge browser, sometimes a failed request that is retried failes again even though we've changed the token. Hence, we're re-trying again with the new token to ensure this is not the case.
     * See: https://app-eu.wrike.com/open.htm?id=1522976858
     */
    const clientAfterRefresh = createClient({
      baseUrl: dbsBaseUrl,
      apiToken: dbsApiToken,
      maxRetryAttempts: 2,
      retryDelay: 5000,
      verbose: true,
    })
    const isOkAfterRefresh = await clientAfterRefresh.ping()
    if (!isOkAfterRefresh) {
      throw new Error(`DbS API Token is not working after refresh.`)
    }
  } catch (error: unknown) {
    sentry.captureException(error as Error)
  }
}

type fetchDatasetsFromDbSOptions = {
  onlyReturnIds?: string[]
  returnIdRegexFilter?: string
}

export async function fetchDatasetsFromDbS(
  datasetInstructions: Partial<DatasetInstruction>[],
  options: fetchDatasetsFromDbSOptions = {}
): Promise<Dataset[]> {
  const finalDatasets: Dataset[] = []
  const finalDatasetsByIndex: Record<number, Dataset[]> = {}
  const authSnap = snapshot(authStore)
  let dbsApiToken = authSnap.user?.dbs_api_token
  let dbsGlobalReadApiToken = authSnap.user?.dbs_global_read_api_token

  if (!authSnap.user) {
    throw new Error(`No user found to get DbS API Token from.`)
  }

  if (!dbsApiToken || !dbsGlobalReadApiToken) {
    const data = await revalidateApiToken()

    dbsApiToken = data.dbsApiToken
    dbsGlobalReadApiToken = data.dbsGlobalReadApiToken
  }

  const client = createClient({
    baseUrl: authSnap.user?.dbs_url,
    apiToken: dbsApiToken,
    onUnauthorized: async () => {
      await revalidateApiToken()
    },
  })

  const globalReadClient = createClient({
    baseUrl: authSnap.user?.dbs_global_read_url,
    apiToken: dbsGlobalReadApiToken,
    onUnauthorized: async () => {
      await revalidateApiToken()
    },
  })

  const dbsInstructions = datasetInstructions.filter((instruction) => instruction.type === 'dbs')

  await Promise.all(
    dbsInstructions.map(async (_instruction, index) => {
      const { datasetInstruction: instruction, earlyReturn } = filterInternalIdsByOnlyReturnIds(
        _instruction,
        options.onlyReturnIds || [],
        options.returnIdRegexFilter
      )
      if (earlyReturn) {
        return
      }

      const contract = instruction.contract as DatasetContractDbS
      const aggregateFunction = getDbSAggregateFunction(instruction)
      const { startTime, endTime, createdAt, createdAtOperator } =
        getDbSStartAndEndTimeFromDatasetInstruction(instruction)
      const metadataFilter = serializeMetadataFilter(contract.metadata_filter)

      let datasets: Pick<DbSDataset, 'id' | 'metadata'>[] = []
      let dataPoints: Pick<DbSDataPoint, 'time' | 'value' | 'datasetId'>[] = []

      if (aggregateFunction === 'latest') {
        const data = await (contract.global_read_api ? globalReadClient : client).getLatestDatasetsAndDataPoints({
          metadataFilter,
        })

        datasets = data.datasets
        dataPoints = data.dataPoints
      } else {
        const data = await (contract.global_read_api ? globalReadClient : client).getDatasetsAndDataPointsByPostRequest(
          {
            startTime,
            endTime,
            metadataFilter,
            aggregateFunction,
            createdAt,
            createdAtOperator,
            fields: ['time', 'value', 'datasetId'],
            datasetFields: ['id', 'metadata'],
          }
        )

        datasets = data.datasets
        dataPoints = data.dataPoints
      }

      // Convert DbS IDs to AbS return_id
      const datasetIdToInternalIdMap: Record<string, string[]> = {}
      datasets.forEach((dataset: DbSDataset) => {
        const id = dataset?.metadata?.internal_id || dataset.id
        let internalId: string[] = [id]
        const renamedId = contract.id_renaming?.[id]

        if (renamedId) {
          if (isArray(renamedId)) {
            internalId = renamedId
          } else {
            internalId = [renamedId]
          }
        }

        datasetIdToInternalIdMap[dataset.id] = internalId
      })

      // Convert data points to AbS format
      const datasetInternalIdToDatasetIndexMap: Record<string, number> = {}
      let datasetsPreProcess: Dataset[] = []
      dataPoints.forEach((dataPoint: DbSDataPoint) => {
        const internalIds = datasetIdToInternalIdMap[dataPoint.datasetId]

        // Duplicate data points when multiple internalIds are mapped to the same dataset
        internalIds.forEach((internalId) => {
          if (datasetInternalIdToDatasetIndexMap[internalId] === undefined) {
            datasetInternalIdToDatasetIndexMap[internalId] = datasetsPreProcess.length
            datasetsPreProcess.push({
              return_id: internalId,
              times: [],
              values: [],
            })
          }

          const index = datasetInternalIdToDatasetIndexMap[internalId]
          const time = Datetime.toISOString(dataPoint.time)

          datasetsPreProcess[index].times.push(time)
          datasetsPreProcess[index].values.push(dataPoint.value)
        })
      })

      // Fill in missing internal_ids with null values
      datasetsPreProcess = addMissingDatasets(datasetsPreProcess, instruction)

      // Post process datasets
      const datasetsPostProcess = postProcessDatasets(datasetsPreProcess, instruction)
      finalDatasetsByIndex[index] = datasetsPostProcess

      // Debug why data response is empty
      if (dataPoints.length === 0) {
        console.warn(`Empty DbS response for Metadata Filter`, { instruction, datasets, dataPoints })

        const dbsUrl = new URL('https://www.databysigholm.com/app/dataset')

        // Add url params so that filters are like '?includeMetadata=key1:value1,key2:value2'
        Object.entries(contract.metadata_filter.include).forEach(([key, value]) => {
          const values = typeof value === 'string' ? [value] : value
          values.forEach((val) => {
            dbsUrl.searchParams.append('includeMetadata', `${key}:${val}`)
          })
        })
        console.debug(`View results at: ${dbsUrl.toString()}`)
      }
    })
  )

  // Sort datasets in the same chunk as dataset instructions are listed
  for (let i = 0; i < dbsInstructions.length; i++) {
    finalDatasets.push(...(finalDatasetsByIndex[i] || []))
  }

  return finalDatasets
}
