import authStore from 'store/auth/auth'
import filterStore from 'store/filter/filter'
import { subscribeOnUpdate } from 'store/zoom/zoom'
import { sortChartItems } from 'ui/uiConfig/components/Chart/Tooltip/tooltip.helper'
import { ChartItem, ChartJS, FilterItems } from 'ui/uiConfig/components/Chart/chartTypes'

import { proxy, snapshot } from 'valtio'
import { subscribeKey } from 'valtio/utils'

import { clone } from 'helpers/global.helper/global.helper'
import { getRoute } from 'helpers/route.helper/route.helper'

let idCounter = 0

const groupIdCharts: Record<string, Record<string, ChartJS>> = {}

type ChartStoreType = {
  groupIdFilterItemIsVisible: Record<string, Record<string, boolean>>
  groupIdHiddenReturnIds: Record<string, Set<string>>
  groupIdItems: Record<string, ChartItem[]>
  chartIdToGroupId: Record<string, string>
  groupIdAxisLimits: Record<string, Record<string, { min: number; max: number }>>
}

export const chartStore = proxy<ChartStoreType>({
  groupIdFilterItemIsVisible: {},
  groupIdHiddenReturnIds: {},
  groupIdItems: {},
  chartIdToGroupId: {},
  groupIdAxisLimits: {},
})

export function generateNewGroupId(): string {
  return `legendGroupId_${idCounter++}`
}

export function setItemsToGroupId(groupId: string, items: ChartItem[], datasets: Dataset[]): void {
  const snap = snapshot(chartStore)
  const datasetIdToNrOfDataPoints: Record<string, number> = {}
  datasets.forEach((dataset) => {
    datasetIdToNrOfDataPoints[dataset.return_id] = dataset.values.filter((v) => v !== null && v !== undefined).length
  })
  const existingItemIds = new Set(
    (snap.groupIdItems[groupId] || []).map((item) => {
      return item.data_id
    })
  )
  const additionalItems = clone(
    items
      .filter((item) => !existingItemIds.has(item.data_id))
      .filter((item) => datasetIdToNrOfDataPoints[item.data_id] > 0)
  )

  if (!additionalItems.length) {
    return
  }

  const existingItems = snap.groupIdItems[groupId] || []

  const updatedItems = [...existingItems, ...additionalItems]

  // Sort by order
  chartStore.groupIdItems[groupId] = sortChartItems(updatedItems)

  // Default hidden items
  additionalItems
    .filter((item) => item.default_hidden)
    .map((item) => item.data_id)
    .map((returnId) => {
      hideReturnIdInGroup({ groupId, returnId })
    })
}

export function setMinMax(chartId: string, axisMinMax: Record<string, { min: number; max: number }>): void {
  const snap = snapshot(chartStore)
  const groupId = snap.chartIdToGroupId[chartId]

  if (!groupId) {
    return
  }

  if (!chartStore.groupIdAxisLimits[groupId]) {
    chartStore.groupIdAxisLimits[groupId] = {}
  }

  Object.entries(axisMinMax).forEach(([axis, { min, max }]) => {
    let hasUpdate = false
    let currentAxisMin = snap.groupIdAxisLimits[groupId]?.[axis]?.min
    let currentAxisMax = snap.groupIdAxisLimits[groupId]?.[axis]?.max

    if (currentAxisMin === undefined || currentAxisMin > min) {
      currentAxisMin = Math.floor(min)
      hasUpdate = true
    }
    if (currentAxisMax === undefined || currentAxisMax < max) {
      currentAxisMax = Math.ceil(max)
      hasUpdate = true
    }

    if (hasUpdate) {
      chartStore.groupIdAxisLimits[groupId][axis] = { min, max }
    }
  })
}

export function initFilterItemStatus(filterItem: FilterItems, groupId: string): void {
  if (chartStore.groupIdFilterItemIsVisible[groupId]?.[filterItem.title] !== undefined) {
    return
  }

  if (!chartStore.groupIdFilterItemIsVisible[groupId]) {
    chartStore.groupIdFilterItemIsVisible[groupId] = {}
  }

  chartStore.groupIdFilterItemIsVisible[groupId][filterItem.title] = !filterItem.default_hidden
}

export function toggleFilterItemStatus({
  groupId,
  filterItem,
  exclusive,
}: {
  groupId: string
  filterItem: FilterItems
  exclusive?: boolean
}): void {
  const snap = snapshot(chartStore)
  const previousStatus = snap.groupIdFilterItemIsVisible[groupId]?.[filterItem.title]
  const isVisible = !previousStatus
  const groupVisibility = clone(snap.groupIdFilterItemIsVisible[groupId] || {}) as Record<string, boolean>

  // Exclusive visibility sets every other filter item to hidden.
  if (exclusive) {
    Object.keys(groupVisibility).forEach((key) => {
      groupVisibility[key] = false
    })
  }

  groupVisibility[filterItem.title] = isVisible
  chartStore.groupIdFilterItemIsVisible[groupId] = groupVisibility

  if (isVisible) {
    showReturnIdInGroup({ groupId, returnId: filterItem.return_ids, exclusive })
  } else {
    hideReturnIdInGroup({ groupId, returnId: filterItem.return_ids })
  }
}

export function showReturnIdInGroup({
  groupId,
  returnId,
  exclusive,
}: {
  groupId: string
  returnId: string | string[]
  exclusive?: boolean
}): void {
  const snap = snapshot(chartStore)
  const hiddenReturnIds = snap.groupIdHiddenReturnIds[groupId] || new Set()

  let visibleReturnIds: Set<string> = new Set()
  if (typeof returnId === 'string') {
    visibleReturnIds.add(returnId)
  } else {
    visibleReturnIds = new Set(returnId)
  }

  if (exclusive) {
    const allItems = snap.groupIdItems[groupId] || []

    allItems
      .filter((item) => !visibleReturnIds.has(item.data_id))
      .forEach((item) => {
        hiddenReturnIds.add(item.data_id)
      })
  }

  visibleReturnIds.forEach((id) => {
    hiddenReturnIds.delete(id)
  })

  chartStore.groupIdHiddenReturnIds = {
    ...snap.groupIdHiddenReturnIds,
    [groupId]: hiddenReturnIds,
  }
}

export function hideReturnIdInGroup({ groupId, returnId }: { groupId: string; returnId: string | string[] }): void {
  const snap = snapshot(chartStore)
  const hiddenReturnIds = snap.groupIdHiddenReturnIds[groupId] || new Set()

  let returnIdsSet: Set<string> = new Set()
  if (typeof returnId === 'string') {
    returnIdsSet.add(returnId)
  } else {
    returnIdsSet = new Set(returnId)
  }

  returnIdsSet.forEach((id) => {
    hiddenReturnIds.add(id)
  })

  chartStore.groupIdHiddenReturnIds = {
    ...snap.groupIdHiddenReturnIds,
    [groupId]: hiddenReturnIds,
  }
}

export function registerChart(groupId: string, chart: ChartJS): void {
  const charts = groupIdCharts[groupId] || {}

  if (chart.id === undefined || charts[chart.id]) {
    return
  }

  if (!groupIdCharts[groupId]) {
    groupIdCharts[groupId] = {}
  }

  groupIdCharts[groupId][chart.id] = chart
}

export function unregisterChart(groupId: string, chart: ChartJS): void {
  if (!groupIdCharts[groupId]) {
    return
  }
  if (groupIdCharts[groupId][chart.id]) {
    delete groupIdCharts[groupId][chart.id]
  }
}

export function drawChartsInGroup(chartId: string): void {
  const snap = snapshot(chartStore)
  const groupId = snap.chartIdToGroupId[chartId]
  const charts = groupIdCharts[groupId] || {}

  Object.values(charts).forEach((chart) => {
    chart.draw()
  })
}

export function showTooltipInGroup(chartId: string, stepIndex: number): void {
  const snap = snapshot(chartStore)
  const groupId = snap.chartIdToGroupId[chartId]
  const charts = groupIdCharts[groupId] || {}
  const otherCharts = Object.values(charts).filter((chart) => chart.id !== chartId)

  otherCharts.forEach((chart) => {
    const activeElements = chart.data.datasets
      .map((d, datasetIndex) => ({
        datasetIndex,
        index: stepIndex,
        hidden: d.hidden,
      }))
      .filter((d) => d.hidden !== true)

    chart.tooltip?.setActiveElements(activeElements)
  })
}

export function hideTooltipInGroup(chartId: string): void {
  const snap = snapshot(chartStore)
  const groupId = snap.chartIdToGroupId[chartId]
  const charts = groupIdCharts[groupId] || {}

  Object.values(charts).forEach((chart) => {
    try {
      chart.tooltip?.setActiveElements([])
    } catch (err: unknown) {
      // Ignore internal chart plugin error
    }
  })
}

export function clearChartState(): void {
  chartStore.groupIdFilterItemIsVisible = {}
  chartStore.groupIdHiddenReturnIds = {}
  chartStore.groupIdItems = {}
  chartStore.groupIdAxisLimits = {}
}

subscribeOnUpdate('chartStore', () => {
  chartStore.groupIdAxisLimits = {}
})

subscribeKey(authStore, 'activeSystem', () => {
  clearChartState()
})

subscribeKey(filterStore.currentActiveTagsPerRoute, getRoute(), () => {
  clearChartState()
})

subscribeKey(filterStore.groupingPerRoute, getRoute(), () => {
  clearChartState()
})
