import { TransifexI18next } from '@transifex/i18next'
import { createNativeInstance, TxNative } from '@transifex/native'

import { TransifexToken } from './localization.helper'

interface CustomBackendOptions {
  tokens: TransifexToken[]
}
class CustomBackend extends TransifexI18next {
  constructor(options: CustomBackendOptions) {
    super(options)
  }

  init(_services: unknown, _options: CustomBackendOptions): void {
    this.txs = this.options.tokens.map((token: string) => {
      return createNativeInstance({ token: token })
    })
  }

  read(
    language: string,
    namespace: string,
    callback: (error: Error | null, data: { [key: string]: any } | null) => void
  ): void {
    const txs: TxNative[] = this.txs
    const promises: Promise<void>[] = []
    txs.forEach((tx) => {
      promises.push(tx.fetchTranslations(language))
    })
    Promise.all(promises)
      .then(() => {
        let data: { [key: string]: string } = {}
        txs.forEach((tx) => {
          data = { ...data, ...this._convertPlurals(tx.cache.getTranslations(language)) }
        })
        callback(null, data)
      })
      .catch((error) => {
        callback(error, null)
      })
  }
}

export default function createCustomBackend(options: CustomBackendOptions): typeof TransifexI18next {
  return new CustomBackend(options)
}
