import authStore from 'store/auth/auth'
import { systemFeatureFlagActive } from 'utils/systemUtils'
import { DeepReadonly } from 'utils/types'

import { useSnapshot } from 'valtio'


export const shouldShowCustomDatepicker = (system: DeepReadonly<System>): boolean => {
  return systemFeatureFlagActive(system, 'optimize_view_custom_datepicker')
}

export const useShouldShowCustomDatepicker = (): boolean => {
  const { activeSystem } = useSnapshot(authStore) as typeof authStore
  return activeSystem ? shouldShowCustomDatepicker(activeSystem) : false
}