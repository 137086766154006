
import Datetime from 'utils/datetime/datetime'

import { TooltipItem, ChartTypeRegistry, TooltipModel } from 'chart.js'
import ChartJS from 'chart.js/auto'
import i18n from 'i18next'
import moment from 'moment'

import arrowUp  from '/img/favicon/arrow-up.svg'
import arrowDown from '/img/favicon/arrow-down.svg'

import { ChartJSDatasetConfig } from '../chartTypes'
import { getTypeObject } from 'helpers/global.helper/global.helper'

const createTooltipHeader = (titleLines: string[], tableHead: HTMLTableSectionElement): void => {
  titleLines.forEach((title: string) => {
    const tr = document.createElement(`tr`)
    tr.style.borderWidth = `0`

    const th = document.createElement(`th`)
    th.style.borderWidth = `0`

    const text = document.createTextNode(title)

    th.appendChild(text)
    tr.appendChild(th)
    tableHead.appendChild(tr)
  })
}

const getArrowInfoBox = (dataCellsArray: HTMLTableDataCellElement[]): void => {
  // This is connected to the show_arrow_icon prop in uiconfig that shows if an items value is positive or negative (buy/sell) for the new elplan module. This could be iterated in the future if we want to use the arrows for other purposes than show the arrows as buy/sell
  const iconArrowUp = document.createElement('img')
  const iconArrowDown = document.createElement('img')
  iconArrowUp.src = arrowUp
  iconArrowDown.src = arrowDown

  iconArrowUp.style.marginRight = '5px'
  iconArrowDown.style.marginRight = '5px'

  const textArrowUp = document.createTextNode(i18n.t('Sell'))
  const textArrowDown = document.createTextNode(i18n.t('Buy'))

  const tdElementUp = document.createElement('td')
  tdElementUp.style.display = 'inline-flex'
  tdElementUp.style.alignItems = 'center'
  tdElementUp.appendChild(iconArrowUp)
  tdElementUp.appendChild(textArrowUp)

  const tdElementDown = document.createElement('td')
  tdElementDown.style.display = 'inline-flex'
  tdElementDown.style.alignItems = 'center'
  tdElementDown.appendChild(iconArrowDown)
  tdElementDown.appendChild(textArrowDown)

  const separator = document.createElement('hr')
  separator.style.width = '100%'
  separator.style.border = '1px solid #ccc'

  const parentTdElement = document.createElement('td')
  parentTdElement.style.display = 'inline-flex'
  parentTdElement.style.gap = '10px'
  parentTdElement.appendChild(tdElementUp)
  parentTdElement.appendChild(tdElementDown)

  dataCellsArray.push(separator)
  dataCellsArray.push(parentTdElement)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createTableRows = (bodyLines: string[], tooltip: any): HTMLTableDataCellElement[] => {

  const dataCellsArray: HTMLTableDataCellElement[] = []

  const mappedBodyLinesToChartItems = sortChartItems(bodyLines.map((body, i) => { //map bodyLines to objects that can be sorted in function
    if (tooltip.dataPoints[i].dataset.order === undefined || tooltip.dataPoints[i].dataset.order === '') {
      return {
        fill: tooltip.dataPoints[i].dataset.fill,
        title: tooltip.dataPoints[i].dataset.label.split(':')[1],
        colors: tooltip.labelColors[i],
        value: tooltip.dataPoints[i].raw,
        showArrowIcon: tooltip.dataPoints[i].dataset.show_arrow_icon,
        borderDash: tooltip.dataPoints[i].dataset.borderDash,
        body: body,
      }
    } else {
      return {
        fill: tooltip.dataPoints[i].dataset.fill,
        title: tooltip.dataPoints[i].dataset.label.split(':')[1],
        order: tooltip.dataPoints[i].dataset.order,
        colors: tooltip.labelColors[i],
        value: tooltip.dataPoints[i].raw,
        showArrowIcon: tooltip.dataPoints[i].dataset.show_arrow_icon,
        borderDash: tooltip.dataPoints[i].dataset.borderDash,
        body: body,
      }
    }
  }))
  let tooltipHasItemWithArrowIcon = false

  //create tooltip row items from sorted objects
  mappedBodyLinesToChartItems.forEach((item:
    {
      fill: boolean,
      title: string,
      colors: { borderColor: string, backgroundColor: string },
      borderDash: boolean,
      value: number,
      showArrowIcon?: boolean,
      body?: any,
      order?: number | string
    }) => {
    const colors = item.colors

    const isFilled = item.fill
    const isDashed = item.borderDash

    const span = document.createElement(`span`)
    span.style.background = isDashed || !isFilled ? null : colors.backgroundColor
    span.style.borderRadius = `50%`
    span.style.borderWidth = `2px`
    span.style.marginRight = `10px`
    span.style.height = `15px`
    span.style.width = `15px`
    span.style.display = `inline-block`
    if (isDashed) {
      span.style.border = `dashed ` + colors.borderColor
    } else if (!isFilled && !isDashed) {
      span.style.border = `2px solid ` + colors.borderColor
    } else {
      colors.borderColor
    }

    const td = document.createElement(`td`)
    td.style.borderWidth = `0`

    const text = document.createTextNode(item.body)
    td.appendChild(span)
    td.appendChild(text)

    if (item.showArrowIcon) {
      tooltipHasItemWithArrowIcon = true
      const icon = document.createElement('img')
      let arrow = arrowUp
      if ((typeof item.value === 'number') && item.value < 0) {
        arrow = arrowDown
      }
      icon.src = arrow
      icon.style.marginLeft = '5px'
      td.appendChild(icon)
    }

    if (item.body.length !== 0) { //only show tooltip item if there is a value
      dataCellsArray.push(td)
    }
  })

  if (tooltipHasItemWithArrowIcon) {
    getArrowInfoBox(dataCellsArray)
  }

  return dataCellsArray
}

const addDataToRows = (
  dataCellsArray: HTMLTableDataCellElement[],
  tableBody: HTMLTableSectionElement,
  tableHead: HTMLTableSectionElement,
  tooltipEl: HTMLDivElement
): void => {
  // Create each row with multiple datacells if the length is too long
  let maxRows = 10
  if (dataCellsArray.length % 10 < 3) {
    maxRows = 8
  }

  const numberOfColumns = Math.ceil(dataCellsArray.length / maxRows)

  for (let i = 0; i < maxRows; i++) {
    const tr = document.createElement(`tr`)
    tr.style.backgroundColor = `inherit`
    tr.style.borderWidth = `0`

    for (let j = 0; j < numberOfColumns; j++) {
      const dataIndex = i + j * maxRows
      const canAdd: boolean = dataIndex < dataCellsArray.length
      if (canAdd) {
        tr.appendChild(dataCellsArray[dataIndex])
      }
    }
    tableBody.appendChild(tr)
  }
  const tableRoot = tooltipEl.querySelector(`table`)

  if (tableRoot) {
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove()
    }

    tableRoot.appendChild(tableHead)
    tableRoot.appendChild(tableBody)
  }
}

const positionOfLineChartTooltip = (
  tooltipEl: HTMLDivElement,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tooltip: any,
  canvasHeight: number,
  chartWidth: number
): void => {
  // Display, position, and set styles for font
  tooltipEl.style.opacity = `1`
  tooltipEl.style.top = Math.floor(canvasHeight / 40) + `vh`
  tooltipEl.style.font = tooltip.options.bodyFont.string
  tooltipEl.style.padding = tooltip.options.padding + `px ` + tooltip.options.padding + `px`
  tooltipEl.style.zIndex = `100`

  let left = Math.floor(tooltip.caretX - 50 - tooltipEl.offsetWidth) + `px`

  if (tooltip.caretX + tooltipEl.offsetWidth < chartWidth) {
    left = Math.floor(tooltip.caretX + 50) + `px`
  }

  tooltipEl.style.left = left
}

const positionOfBarChartTooltip = (
  tooltipEl: HTMLDivElement,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tooltip: any,
  canvasHeight: number,
  chartWidth: number
): void => {
  // Display, position, and set styles for font
  tooltipEl.style.opacity = `1`
  tooltipEl.style.top = Math.floor(canvasHeight / 40) + `vh`
  tooltipEl.style.font = tooltip.options.bodyFont.string
  tooltipEl.style.padding = tooltip.options.padding + `px ` + tooltip.options.padding + `px`
  tooltipEl.style.zIndex = `100`

  if (tooltip.x + tooltipEl.offsetWidth < chartWidth) {
    tooltipEl.style.left = Math.floor(tooltip.x + 50) + `px`
  } else {
    tooltipEl.style.left = Math.floor(tooltip.x - 50 - tooltipEl.offsetWidth) + `px`
  }
}

export const externalTooltipHandler = (context: TooltipModel<keyof ChartTypeRegistry>): void => {
  const { chart, tooltip } = context
  const tooltipEl = getOrCreateTooltip(chart)

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = `0`
    return
  }

  // Set Text
  if (tooltip.body) {
    const titleLines: string[] = tooltip.title || []
    const bodyLines: string[] = tooltip.body.map((b) => b.lines)

    const tableHead = document.createElement(`thead`)
    const tableBody = document.createElement(`tbody`)

    if (chart.config.type === `line`) {
      createTooltipHeader(titleLines, tableHead)
    }
    const tableRows: HTMLTableDataCellElement[] = createTableRows(bodyLines, tooltip)
    addDataToRows(tableRows, tableBody, tableHead, tooltipEl)
  }

  const { height: canvasHeight } = chart.canvas
  const { width: chartWidth } = chart.chartArea

  if (chart.config.type === `line`) {
    positionOfLineChartTooltip(tooltipEl, tooltip, canvasHeight, chartWidth)
  } else if (chart.config.type === `bar`) {
    positionOfBarChartTooltip(tooltipEl, tooltip, canvasHeight, chartWidth)
  }
}

export const getOrCreateTooltip = (chart: ChartJS): HTMLDivElement => {
  let tooltipEl = chart?.canvas?.parentNode?.querySelector(`div`)

  if (!tooltipEl) {
    tooltipEl = document.createElement(`div`)
    tooltipEl.style.background = `rgba(249, 249, 249, 0.95)`
    tooltipEl.style.borderRadius = `3px`
    tooltipEl.style.color = `#000000`
    tooltipEl.style.opacity = 1
    tooltipEl.style.pointerEvents = `none`
    tooltipEl.style.position = `absolute`
    tooltipEl.style.width = `auto`

    const table = document.createElement(`table`)
    table.style.margin = `0px`

    tooltipEl.appendChild(table)
    chart.canvas.parentNode.appendChild(tooltipEl)
  }

  tooltipEl.classList.add(`ChartJSTooltip`)
  return tooltipEl
}

export const tooltipTitle = (tooltipItem: TooltipItem<keyof ChartTypeRegistry>[]): string => {
  const isoString = Datetime.toISOString(tooltipItem[0].label)
  const nextHour = Datetime.toLocalTime(Datetime.toISOString(moment(tooltipItem[0].label).add(1, `hour`)), `hour`)
  const showTimeAsInterval = tooltipItem.find((item) => item.dataset.tooltip_show_time_as_interval)

  // If any item has show time as interval, display an interval for the title of the tooltip
  if (localStorage.getItem('language') === 'en') {
    return Datetime.toLocalTime(isoString, `dayWithDate`)
  }

  if (tooltipItem.length && showTimeAsInterval) {
    return Datetime.toLocalTime(isoString, `longDayText`) + ` - ` + nextHour
  } else if (tooltipItem.length) {
    return Datetime.toLocalTime(isoString, `longDayText`)
  }
  return ``
}

export const tooltipLabel = (
  tooltipItem: TooltipItem<keyof ChartTypeRegistry> & { dataset: ChartJSDatasetConfig }
): string => {
  if (!tooltipItem.dataset.label) {
    return ``
  }

  if (tooltipItem.parsed.y === 0 && tooltipItem.dataset.tooltip_do_not_show_zero_values) {
    return ``
  }

  if (tooltipItem.dataset.tooltip_do_not_show_value) {
    return cleanDatasetLabel(tooltipItem.dataset.label)
  }

  const typeObj = getTypeObject(
    tooltipItem.parsed.y,
    tooltipItem.dataset.data_type,
    tooltipItem.dataset.unit,
    tooltipItem.dataset.decimals
  )

  const value = tooltipItem.dataset.tooltip_always_positive
    ? Math.abs(typeObj.value as unknown as number)
    : typeObj.value

  const label = cleanDatasetLabel(tooltipItem.dataset.label)
  return `${label}: ${value} ${typeObj.unit}`
}

export const tooltipLabelBarChart = (
  tooltipItem: TooltipItem<keyof ChartTypeRegistry> & { dataset: ChartJSDatasetConfig }
): string => {
  if (!tooltipItem.dataset.label) {
    return ``
  }
  const typeObj = getTypeObject(
    tooltipItem.dataset.data[0],
    tooltipItem.dataset.data_type,
    tooltipItem.dataset.unit,
    tooltipItem.dataset.decimals
  )

  const value = tooltipItem.dataset.tooltip_always_positive
    ? Math.abs(typeObj.value as unknown as number)
    : typeObj.value

  const label = cleanDatasetLabel(tooltipItem.dataset.label)
  return `${label}: ${value} ${typeObj.unit}`
}

function cleanDatasetLabel(label: string): string {
  if (!label.startsWith('INDEX')) {
    return label
  }

  /*
  [2023-11-27] Mattias:
  ChartJS v4.4.0 has a bug where datasets with the same label are hiding each other, even when they are on different y-axes or stack-groups. Hence, prefixing with the index-key in chart.helper when creating the chartjs dataset. The temporary unique label is then `INDEX${index}:${label}` and we want to clean it to just `${label}` here.
  */

  const removeUpUntilIndex = label.indexOf(':') + 1
  if (removeUpUntilIndex === -1) {
    return label
  }

  return label.substring(removeUpUntilIndex)
}

export function sortChartItems(items: any): any {
  // The function sorts according to the following:
  // 1. Based on order (0 first).
  // 2. Filled first, then dashed.
  // 3. If the order number is the same, sort by title (filled and dashed separately).
  // 4. If there is no order, sort by title (mixed filled and dashed).
  // 5. If some items has order and other don't, sort by order first.

  const sortedItems: any = items
  sortedItems.sort((a, b) => {
    const titleA = a?.title ? a.title.toLowerCase() : ''
    const titleB = b?.title ? b.title.toLowerCase() : ''
    const filledA = a?.fill ?? false
    const filledB = b?.fill ?? false


    if (a.order !== undefined && a.order !== '' && b.order !== undefined && b.order !== '') {
      if (a.order === b.order) {
        if (filledA && filledB) {
          return titleA < titleB
        } else if (!filledA && !filledB) {
          if (titleA > titleB) {
            return 1
          }
          if (titleA < titleB) {
            return -1
          }
        }
      }
      if (filledA !== filledB) {
        return filledA ? -1 : 1
      } else if (!filledA !== !filledB) {
        return !filledA ? 1 : -1
      } else {
        return a.order - b.order
      }
    } else if (a.order !== undefined && a.order !== '') {
      return -1
    } else if (a.order !== undefined && b.order !== '') {
      return 1
    } else {
      if (titleA > titleB) {
        return 1
      }
      if (titleA < titleB) {
        return -1
      }
    }
  })

  return sortedItems
}