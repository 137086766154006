import { TooltipTextType } from 'ui/components/BaseChart/components/CustomTooltip/CustomTooltip'
import { Serie } from 'ui/components/BaseChart/types'

import i18n from 'i18next'

export function getEmptyChartText(chartType: TooltipTextType): string {
  switch (chartType) {
    case `optimization`:
      return i18n.t(`There is no fetched data, have you done an optimization lately? `)
    case `outcome`:
      return i18n.t(`There is no fetched data`)
    case `followupOpt`:
      return i18n.t(`No followup optimization for the period`)
    case `followupOut`:
      return i18n.t(`No measvalues was found for this period`)
    default:
      return ``
  }
}

export function sortSeriesByStackOrder(a: Serie, b: Serie): number {
  // sort by stack_order
  if (a.stack_order !== b.stack_order) {
    if (a.stack_order && b.stack_order) return a.stack_order - b.stack_order
    if (a.stack_order || b.stack_order) {
      if (a.stack_order && !b.stack_order) return 1
      if (!a.stack_order && b.stack_order) return -1
    }
  }

  // lastly if same type and same stack_order sort by name
  if (a.display_name > b.display_name) return -1
  if (a.display_name < b.display_name) return 1

  return 0
}

export type pieChartThresholds = {
  greenMinValue: number
  orangeMinValue: number
}

export function setPieChartColour(
  dataValue: string | number,
  thresholds: pieChartThresholds = { greenMinValue: 95, orangeMinValue: 90 }
): string {
  const value = Number(dataValue)
  if (value >= thresholds.greenMinValue) return `#5aa832`
  else if (value < thresholds.greenMinValue && value >= thresholds.orangeMinValue) return `#f1c202`
  else if (value < thresholds.orangeMinValue) return `#e0182d`
  else return ``
}
