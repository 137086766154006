import { apiClient } from 'api/apiClient/apiClient'
import { useAuth } from 'ui/components/AuthContext/AuthContext'

import { useQuery } from 'react-query'

export type Module = { name: string }

export const MODULES_QUERY_KEY = `modules`
export function useModules() {
  const { systemId } = useAuth()

  return useQuery(
    [MODULES_QUERY_KEY, { system: systemId }],
    () =>
      apiClient<Module[]>(`modules`, {
        params: {
          system: systemId,
        },
      }),
    {
      enabled: !!systemId,
    }
  )
}
